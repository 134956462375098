<template>
	<div>
		<h5>Reservation Information</h5>
		<a-divider />
		<a-form-model ref="step1" :model="step1" :rules="rules">
			<a-form-model-item prop="isManual" class="mb-0">
				<template slot="label">
					Signing Method
					<a-tooltip placement="right" overlayClassName="change-tooltip-color">
						<template slot="title" style="width: 400px;">
							Select e-signature if at least one party needs to sign digitally.
						</template>
						<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
					</a-tooltip>
				</template>
				<a-radio-group v-model="step1.isManual">
					<a-radio-button :value="false">
						<a-icon type="file-protect" /> E-Signature
					</a-radio-button>
					<a-radio-button :value="true">
						<a-icon type="highlight" /> Print &amp; Sign
					</a-radio-button>
				</a-radio-group>
			</a-form-model-item>

			<p v-if="!integrated.active && !step1.isManual" class="aC py-1 mb-0 bg-danger text-white rounded"
				style="display:inline-flex; max-width:600px">
				<a-icon class="text-lg" type="warning" />
				<span class="px-1 py-2">
					You are not currently integrated with our signing service.
					Please check your <router-link class="text-primary" to="/settings?docusign">settings</router-link> to
					integrate with a signing service.
					Alternatively, you can choose the <strong>Print &amp; Sign</strong> option.
				</span>
			</p>
			<p v-if="step1.isManual" class="aC py-1 mb-0 bg-med-gray text-white rounded bg-info"
				style="display:inline-flex; max-width:600px">
				<a-icon class="text-lg" type="note" />
				<span class="px-1 py-2">
					Please note that this will be a Manual Reservation. <br />You will have to Scan and Upload the signed
					contract.<br />
				</span>
			</p>

			<a-form-model-item :label="`Please choose the Agreement of Contract template`" prop="template" class="mt-3">
				<a-select :size="$mq === 'lg' ? null : 'large'" option-label-prop="label" v-model="step1.template"
					style="max-width:300px;">
					<a-select-option :value="template.id" v-for="template in reservationTemplates" :key="template.id"
						:label="template.name">
						<div class="dF aC template-list">
							<div>
								<i class="fe fe-file-text" style="font-size:15px;" />
							</div>
							<div class="f1 ml-2">
								{{ template.name }}
								<small class=block>{{ template.pages.length }} page(s) </small>
							</div>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>

export default {
	props: ['value', 'setManual', 'reservation'],
	watch: {
		'step1.isManual'(val) {
			this.setManual(val)
		}
	},
	data: () => ({
		step1: {
			isManual: false,
			template: ''
		},
		rules: {
			template: [
				{ required: true, message: 'Please choose a contract', trigger: 'blur' },
			],
			isManual: [
				{ required: true, message: 'Please choose a signing method', trigger: 'blur' },
			]
		},
	}),
	methods: {
		validate() {
			let self = this
			if (!this.$refs.step1) {
				return;
			}
			this.$refs.step1.validate(valid => {
				if (valid) {
					self.$emit('done', this.step1)
				} else {
					return false;
				}
			})
		},
	},
	computed: {
		integrated() {
			return this.$store.state.appData.vendors.docusign
		},

		reservationTemplates() {
			return this.contractTemplates.filter(x => x.type === 'reservation')
		},

		contractTemplates() {
			let templates = this.$store.state.appData.templates || {}
			return Object.values(templates).filter(x => {
				return x.pages && x.pages.length
			})
		},
	},
	mounted() {
		this.$emit('setFn', this.validate)

		this.step1.isManual = this.reservation.isManual || this.step1.isManual
		this.step1.template = this.reservation.template || this.step1.template
	}
}
</script>

<style>
.template-list i {
	font-size: 25px;
	padding: 6px;
	background: var(--light-purple);
	border-radius: 50%;
	width: 15px;
	height: 15px;
}
</style>
