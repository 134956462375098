<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y:scroll; z-index: 100;"
		ref="scroller" @scroll="scrollingContainerShadow">
		<div class="dF fC f1">
			<div class="dF jSB aS">
				<h4 class="pb-2">New Reservation</h4>
				<div v-if="timeDifference > 0" class="px-3 py-1"
					style="background-color: #FFFFFF; border: 1px solid #e8e8e8;">
					<div><span style="font-weight: bold;">{{ instance.productType === 'highrise' ? 'Unit' : 'Lot' }} {{
						selectedLot }}</span> will be available again in <span
							style="color: var(--orange); font-weight: bold;">{{ formatTime(timeDifference) }}</span>. Please
						hurry to secure your {{ instance.productType === 'highrise' ? 'unit' : 'lot' }}.</div>
				</div>
			</div>
			<a-card class="pt-2 md__px-2 f1 dF fC" style="max-width:1600px;width:100%"
				:bodyStyle="{ display: 'flex', flexDirection: 'column', flex: 1 }">
				<a-steps :current="current" class="mb-5" :size="$mq === 'lg' ? 'default' : 'small'">
					<a-step title="Step 1" description="Provide reservation information" />
					<a-step title="Step 2" description="Provide Participant information" />
					<a-step title="Step 3" description="Product Details" />
					<a-step title="Step 4" description="Finalize Document" />
					<a-step v-if="!isManual" title="Step 5"
						:description="isManual ? 'Print & Sign' : 'Set Up Signing Order'" />
				</a-steps>

				<div class="f1" v-if="waitForResponse">
					<transition name="slide-fadein-right" mode="out-in">
						<component :setManual="setManual" :reservation="reservation" :is="`Step${current + 1}`"
							:key="current + refreshed" @setFn="setValidatingFn" @done="next" v-model="reservation" />
					</transition>
				</div>

				<div>
					<a-divider />
					<div class="dF aC jSB mt-3">
						<a-button size="large" class="mr-5" type="secondary" @click="prev">BACK</a-button>
						<div class="dF aC" style="gap: 25px">
							<!-- <a-button v-if="!$route.params.id" size="large" type="primary"
								@click="$store.commit('SET_SAVING_AS_DRAFT'); currFn()" ghost>SAVE AS A DRAFT</a-button> -->
							<a-button :disabled="!isManual && !integrated.active" size="large" type="primary"
								@click="currFn">{{ nextLookup[current + 1] ? nextLookup[current + 1] : 'NEXT' }}</a-button>
						</div>
					</div>
				</div>
			</a-card>
		</div>
		<a-modal v-model="showWarnings" title="Transaction Already Exists" @ok="handleTransactionSelection" width="600px">
			<a-form-model-item label="Select a reservation you want to continue with">
				<a-radio-group v-model="selectedTransaction">
					<a-radio v-for="(reservation) in linkedTransactions" :key="reservation.id" :style="radioStyle"
						:value="reservation.id">
						{{ transactionName(reservation) }}
						<span class="tT">({{ statusLookup[reservation.status] || reservation.status }})</span>
						<span class="text-med-gray"> ID: {{ reservation.id }} </span>
					</a-radio>
					<a-radio :style="radioStyle" :value="'new'">
						Create New
					</a-radio>
				</a-radio-group>
			</a-form-model-item>
		</a-modal>
	</div>
</template>

<script>
import { scrollingContainerShadow } from 'bh-mod'
import Step1 from '@/components/steps/ReservationStep1'
import Step2 from '@/components/steps/ReservationStep2'
import Step3 from '@/components/steps/ReservationStep3'
import Step4 from '@/components/steps/ReservationStep4'
import Step5 from '@/components/steps/ReservationStep5'
import moment from 'moment'

export default {
	components: {
		Step1, Step2, Step3, Step4, Step5
	},
	data() {
		return {
			isManual: false,
			refreshed: 0,
			currValidate: () => { },
			reservation: {
				isManual: false,
				template: '',
				purchasers: [],
				product: {},
				contract: {},
				offer: {},
				signingParty: [],
				other: {},
				query: {},
				contactTags: []
			},
			rep: {},
			current: 0,
			currentStepObj: {},
			timeDifference: 0,
			productTimer: null,
			showWarnings: false,
			linkedTransactions: [],
			selectedTransaction: 'new',
			radioStyle: {
				display: 'block',
				height: '30px',
				lineHeight: '30px',
			},
			statusLookup: {
				void: 'Voided',
				created: 'Pending'
			}
		}
	},
	computed: {
		selectedLot() {
			return this.$store.state.appData.selectedLot
		},
		productLock() {
			return this.$store.state.appData.productLock
		},
		instance() {
			return this.$store.state.instance
		},
		nextLookup() {
			return {
				4: this.isManual ? 'NEXT: REVIEW & DOWNLOAD' : 'NEXT: SIGNING ORDER',
				5: 'NEXT: REVIEW & SEND'
			}
		},
		integrated() {
			return this.$store.state.appData.vendors.docusign
		},
		savingAsDraft() {
			return this.$store.state.savingAsDraft;
		},

		waitForResponse() {
			let sParams = new URLSearchParams(location.search)
			const draft = sParams.get('draft');
			if (draft) {
				return !!this.reservation.template
			}
			return true;
		},
		user() {
			return this.$store.state.user.user
		},
	},
	watch: {
		current(val) {
			this.$refs.scroller.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		productLock(val) {
			if (val != 0) {
				let time = val
				this.timeDifference = moment(time).diff(moment(), 'milliseconds')
				this.startCountdown();
			}
		}
	},
	methods: {
		formatTime() {
			return moment(this.timeDifference).format('mm:ss')
		},
		startCountdown() {
			this.productTimer = setInterval(() => {
				this.timeDifference -= 1000;

				if (this.timeDifference <= 0) {
					this.$store.commit('SET_PRODUCT_LOCK', { time: 0, name: '' })
					clearInterval(this.productTimer);
				}
			}, 1000);
		},
		setManual(is) {
			this.isManual = is
		},
		scrollingContainerShadow,
		setValidatingFn(validateFn) {
			this.currValidate = validateFn
		},
		currFn() {
			return this.currValidate()
		},
		updatePurchasers(array) {
			array.forEach(applicant => {
				if (!applicant.email) {
					return;
				}

				let pid = Date.now();

				let purchaser = {
					id: pid,
					firstName: applicant.firstName,
					middleName: applicant.middleName,
					lastName: applicant.lastName,
					email: applicant.email,
					phone: applicant.phone && applicant.phone.toString(),
					company: applicant.company,
					occupation: applicant.occupation || '',
					business: applicant.business && applicant.business.toString() || '',
					cell: applicant.cell && applicant.cell.toString() || '',
					dob: applicant.dob || '',
					issuing: applicant.issuing || '',
					issuingCountry: applicant.issuingCountry || '',
					expiry: applicant.expiry || '',
					idType: applicant.idType || 'pp',
					idValue: applicant.idValue || '',
					passportOtherValue: applicant.passportOtherValue || '',
					passportOtherIssuing: applicant.passportOtherIssuing || '',
					passportOtherIssuingCountry: applicant.passportOtherIssuingCountry || '',
					passportOtherExpiry: applicant.passportOtherExpiry || '',
					address1: applicant.address || applicant.address1 || '',
					address2: applicant.address2 || '',
					postal: applicant.postal || '',
					country: applicant.country || '',
					region: applicant.region || '',
					city: applicant.city || '',
					jobTitle: applicant.jobTitle || '',
					ssnsin: applicant.ssnsin || '',
					fullName: `${applicant.firstName} ${applicant.lastName}`,
					address: `${applicant.address1}${applicant.address2 ? ', ' + applicant.address2 : ''}`,
					cityRegionPostalZip: `${applicant.city}, ${applicant.region}, ${applicant.postal}`,
					fullAddress: `${applicant.address || applicant.address1}, ${applicant.address2}, ${applicant.city}, ${applicant.region}, ${applicant.postal}`.replaceAll(', , ', ', ').trim(),
					licenseIdType: applicant.licenseIdType || '',
					passportOtherIdType: applicant.passportOtherIdType || ''
				}
				this.reservation.purchasers.push(purchaser)
			})
		},

		updateReps(agent = {}, solicitor = {}) {
			this.reservation.reps = {
				agent: {
					firstName: agent.firstName || '',
					lastName: agent.lastName || '',
					email: agent.email || '',
					phone: agent.phone || '',
					cell: agent.cell || '',
					company: agent.company || '',
					jobTitle: agent.jobTitle || '',
					address1: agent.address1 || '',
					address2: agent.address2 || '',
					country: agent.country || '',
					region: agent.region || '',
					city: agent.city || '',
					postal: agent.postal || '',
					id: 'agent',
					address: `${agent.address1}${agent.address2 ? ', ' + agent.address2 : ''}`,
					cityRegionPostalZip: `${agent.city}, ${agent.region}, ${agent.postal}`,
					fullAddress: `${agent.address1}, ${agent.address2}, ${agent.city}, ${agent.region}, ${agent.postal}`.replaceAll(', , ', ', ').trim()
				},
				solicitor: {
					firstName: solicitor.firstName || '',
					lastName: solicitor.lastName || '',
					email: solicitor.email || '',
					cell: solicitor.cell || '',
					phone: solicitor.phone || '',
					company: solicitor.company || '',
					jobTitle: solicitor.jobTitle || '',
					address1: solicitor.address1 || '',
					address2: solicitor.address2 || '',
					country: solicitor.country || '',
					region: solicitor.region || '',
					city: solicitor.city || '',
					postal: solicitor.postal || '',
					id: 'solicitor',
					address: `${solicitor.address1}${solicitor.address2 ? ', ' + solicitor.address2 : ''}`,
					cityRegionPostalZip: `${solicitor.city}, ${solicitor.region}, ${solicitor.postal}`,
					fullAddress: `${solicitor.address1}, ${solicitor.address2}, ${solicitor.city}, ${solicitor.region}, ${solicitor.postal}`.replaceAll(', , ', ', ').trim()
				}
			}
		},
		prev() {
			if (this.current === 0) {
				let self = this
				return this.$confirm({
					title: `Are you sure you want to exit creating reservation?`,
					okText: 'Exit',
					okType: 'danger',
					centered: true,
					onOk() {
						self.$router.push('/reservation')
					}
				})
			} else {
				this.current--
			}
		},
		next(e) {
			if (this.current == 0) {
				$.extend(true, this.reservation, e)
				this.current++
			} else if (this.current == 1) {
				let err = false
				e.purchasers.forEach(x => {
					if (!(x.firstName.trim() && x.lastName.trim() && x.email.trim() && x.phone && (x.idValue || x.passportOtherValue) && x.address1 && x.city && x.region && x.country)) {
						err = true
					}
				})
				if (err) return this.$message.error('Some of purchasers information are missing. Please fill out the required information.')

				$.extend(true, this.reservation, e)
				if (this.savingAsDraft) {
					this.draftTransaction()
				} else {
					this.current++
				}
			} else if (this.current == 2) {
				this.reservation.product = e.product
				$.extend(true, this.reservation.offer, e.offer)

				const pkg = e.product.unit && e.product.unit.package;
				if (pkg && pkg.other && pkg.other.addons) {
					const newParking = pkg.other.addons.parking && pkg.other.addons.parking.map(p => { return { ...p, preDefined: true } }) || [];
					const newLockers = pkg.other.addons.lockers && pkg.other.addons.lockers.map(l => { return { ...l, preDefined: true } }) || [];
					const newBikeRacks = pkg.other.addons.bikeRacks && pkg.other.addons.bikeRacks.map(b => { return { ...b, preDefined: true } }) || [];

					pkg.other.addons = {
						parking: [...newParking],
						lockers: [...newLockers],
						bikeRacks: [...newBikeRacks]
					}

					this.reservation.product.unit.package.other.addons = pkg.other.addons
					this.reservation.product.unit.packages[0].other.addons = pkg.other.addons
				}

				if (this.savingAsDraft) {
					this.draftTransaction()
				} else {
					this.current++
				}
			} else if (this.current == 3) {
				if (this.savingAsDraft) {
					$.extend(true, this.reservation, e)
					return this.draftTransaction()
				}
				let { package: pkg, lot } = e
				if (typeof pkg === 'object' && this.reservation.product.unit && this.reservation.product.unit.packages) {
					this.reservation.product.unit.package = JSON.parse(JSON.stringify(pkg))
					this.reservation.product.unit.packages[0] = JSON.parse(JSON.stringify(pkg))
				}
				if (typeof lot === 'object') {
					this.reservation.product.lot = JSON.parse(JSON.stringify(lot))
				}
				$.extend(true, this.reservation.offer, e.offer)
				$.extend(true, this.reservation.other, e.other)

				if (!this.savingAsDraft) {
					this.current++
				}
			} else {
				$.extend(true, this.reservation, e)
				if (this.savingAsDraft) {
					this.draftTransaction()
				} else {
					this.current++
				}
			}
		},

		// save reservation as a draft so that user can continue from where they have left
		draftTransaction() {
			this.$store.commit('LOAD', true)
			let reservation = this.reservation;
			const transactionId = this.$route.query.draft

			this.$api[transactionId ? 'put' : 'post'](`/transactions/:instance/${transactionId || 'draft'}`, reservation)
				.then(resp => {
					if (resp && resp.data && resp.data.id) {
						let tr = resp.data
						this.$store.commit('SET_TRANSACTION', { id: tr.id, value: tr })
						this.$router.push('/')
						this.$message.success('Transaction saved as a draft successfully.')
					}
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while saving your reservation as a draft. Please try again.`))
					}
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.$store.commit('CLOSE_PREVIEW')
					this.$store.commit('SET_SAVING_AS_DRAFT', false)
				})
		},

		async fetchLinkedTransactions(reservationId) {
			try {
				this.linkedTransactions = [];
				const { data } = await this.$api.get(`/transactions/:instance?reservation=${reservationId}&archived=false`)

				if (data.items && data.items.length) {
					let transactions = data.items;
					const reservation = transactions.find(t => ['created', 'pending', 'completed'].includes(t.status));

					if (reservation) {
						let self = this;
						this.$confirm({
							title: `${reservation.status === 'completed' ? 'Completed' : 'Pending'} Transaction`,
							content: h => <div>Transaction already exists with the selected reservation. Click OK to review existing reservation, or CANCEL to select another reservation to transact.</div>,
							centered: true,
							onOk: () => {
								self.$router.push(`/reservation/${reservation.id}`)
							},
							onCancel() {
								self.$router.push('/')
							}
						})
					} else {
						this.showWarnings = true;
						this.linkedTransactions = transactions
					}
				}
			} catch (error) {
				console.error(error)
			}
		},

		handleTransactionSelection() {
			if (this.selectedTransaction === 'new') {
				this.showWarnings = false;
			} else {
				let reservation = this.linkedTransactions.find(tx => tx.id === this.selectedTransaction)
				if (reservation.status === 'draft') {
					this.$router.push(`/new?draft=${this.selectedTransaction}`)
					this.$router.go();
				} else {
					this.$router.push(`/reservation/${this.selectedTransaction}`)
				}
			}
		},

		transactionName(reservation) {
			let { product = {}, purchasers = [] } = reservation;
			let name = ''
			if (this.instance.productType == 'highrise') {
				if (product && product.floor && product.unit) {
					name = `${product.floor.name} (Unit #${product.unit.unitNumber})`
				} else if (product && product.unit) {
					name = 'Unit: ' + (product.unit.name || 'N/A')
				} else {
					name = 'Unit N/A'
				}
			} else {
				if (product && product.lot) {
					name = `${product.lot.address && (product.lot.address + ' - ') || ''}Lot ${product.lot && product.lot.name}${product?.lot?.block ? ` (Block ${product.lot.block})` : ''}  (${product.unit && product.unit.unitGroup && product.unit.unitGroup.name} ${product.unit && product.unit.name} - ${product.unit && product.unit.unitGroup && product.unit.unitGroup.size}' ${product.unit && product.unit.unitGroup && product.unit.unitGroup.type})`
				} else if (product && product.unit) {
					name = 'Unit: ' + (product.unit.name || 'N/A')
				} else {
					name = 'Lot N/A'
				}
			}

			if (purchasers && purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`

			return name;
		},

		editingRights(reservation) {
			let canEdit = false;
			if (['submitted'].includes(reservation.status) && (!reservation.createdBy || (reservation.createdBy.id === this.user.id))) {
				canEdit = true;
			}

			return canEdit;
		}
	},
	async created() {
		this.$store.dispatch('FETCH_TAGS')

		this.timeDifference = 0
		this.$store.commit('SET_PRODUCT_LOCK', { time: 0, name: '' })

		let params = new URLSearchParams(location.search)
		let reservationId = this.$route.params && this.$route.params.id

		if (reservationId) {
			this.$store.commit('LOAD', true)
			try {
				let { data } = await this.$api.get(`/reservations/:instance/${reservationId}`)
				let canEdit = this.editingRights(data);
				if (!canEdit) {
					this.$message.error('You are not allowed to edit this reservation. Please contact the administrator.');
					this.$router.push(`/reservations`)
					return;
				}
				this.updatePurchasers([...data.purchasers])
				$.extend(true, this.reservation, data)
				this.refreshed = Date.now()
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying to get reservation details. Please try again.`))
				}
			}
			this.$store.commit('LOAD', false)
		}
	},
	beforeDestroy() {
		clearInterval(this.productTimer)
	},
}
</script>

<style lang="scss" scoped>
</style>
