<template>
	<div>
		<h5>Product Details</h5>
		<a-divider />
		<div class="dF fw">
			<a-form-model ref="step3" :model="step3" class="w-1/2" v-if="instance.productType === 'lowrise'">
				<a-form-model-item label="Select Lot" prop="lot" :rules="req('Please select a Lot')">
					<a-tooltip v-if="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundLot"
						overlayClassName="change-tooltip-color">
						<template slot="title">
							In order to change the lot, please go back to CRM Opportunities and select your desired lot.
						</template>
						<a-icon type="question-circle"
							style="line-height: 25px; margin-top: 5px; color: var(--orange); position: absolute; z-index: 2; top: -43px; left: 75px;" />
					</a-tooltip>
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.lot"
						:disabled="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundLot">
						<a-select-option :value="lot.id" v-for="lot in lots" :key="lot.id">
							Lot {{ lot.name }} <span v-if="lot.block">(Block {{ lot.block }})</span> <span
								v-if="lot.status !== 'available'" style="background-color:orange; font-size:12px; ">({{
									lot.status[0].toUpperCase() +
									lot.status.substr(1) }})</span>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select the Model Elevation" v-if="step3.lot && units && units.length" prop="unit"
					:rules="req('Please select a Unit')">
					<a-tooltip v-if="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundModel"
						overlayClassName="change-tooltip-color">
						<template slot="title">
							In order to change the model elevation, please go back to CRM Opportunities and select your
							desired model elevation.
						</template>
						<a-icon type="question-circle"
							style="line-height: 25px; margin-top: 5px; color: var(--orange); position: absolute; z-index: 2; top: -43px; left: 172px;" />
					</a-tooltip>
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.unit"
						:disabled="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundModel">
						<a-select-option :value="item.id" v-for="item in units" :key="item.id">
							{{ item.unitGroup.name }} - <em>{{ item.name }} </em> <span
								v-if="item.unitGroup.isStandingInventory"> (Standing Inventory)</span>
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<p v-else-if="loadingUnits">Loading Units ...</p>
				<p v-else-if="(step3.unit === false || (units.length === 0 && step3.lot)) && !loadingUnits"
					class="text-danger">There was an error displaying the units, Please contact the administrator to resolve
					this issue.</p>

				<a-form-model-item label="Select the Floorplan Package" v-if="packages && packages.length > 1"
					prop="package" :rules="req('Please select a Unit Floorplan Package')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.package">
						<a-select-option :value="item.id" v-for="item in packages" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<template v-if="step3.unit && currentUnit.id">
					<a-divider />
					<a-form-model-item label="Suggested Price (MSRP)">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								This is the Manufacturer's Suggested Retail Price
								<p class="mt-2">Lot Premiums: ${{ formatPrice(lotPremiums) }}<br />
									Model Price: ${{ currentPackage && formatPrice(currentPackage.price) }}</p>
							</template>
							<a-input-number :disabled="true" style="width:300px; max-width:100%;" :value="msrp"
								:formatter="value => '$ ' + formatPrice(value)" />
						</a-tooltip>
					</a-form-model-item>
				</template>
			</a-form-model>

			<a-form-model ref="step3" :model="step3" class="w-1/2" v-else-if="instance.productType === 'highrise'">
				<a-form-model-item label="Select Floor" prop="floor" :rules="req('Please select a Floor')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.floor">
						<a-select-option :value="floor.id" v-for="floor in condoFloors" :key="floor.id"
							:disabled="floor.disabled || floor.sold">
							{{ floor.name }} <em v-if="floor.street"> ({{ floor.sold ? '- (SOLD OUT)' : '' }})</em>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select Unit" v-if="step3.floor" prop="unit" :rules="req('Please select a Unit')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.unit">
						<a-select-option v-for="item in floorUnits"
							:disabled="!['available', 'hold'].includes(item.salesStatus)" :value="item.id" :key="item.id">
							<em>{{ item.name }} - ({{ item.unitNumber }})</em>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select the Floorplan Package" v-if="packages && packages.length > 1"
					prop="package" :rules="req('Please select a Unit Floorplan Package')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.package">
						<a-select-option :value="item.id" v-for="item in packages" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<template v-if="step3.unit && currentUnit.id">
					<a-divider />
					<a-form-model-item label="Suggested Price (MSRP)">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								This is the Manufacturer's Suggested Retail Price
								<p class="mt-2">
									Unit Premiums: ${{ formatPrice(unitPremiums) }}<br />
									Unit Price: ${{ currentPackage.price && formatPrice(currentPackage.price) }}
								</p>
							</template>
							<a-input-number :disabled="true" style="width:300px; max-width:100%;" :value="msrp"
								:formatter="value => '$ ' + formatPrice(value)" />
						</a-tooltip>
					</a-form-model-item>
				</template>
			</a-form-model>

			<div class="w-1/2 dF jC pt-4 aC">
				<div style="max-width: 400px;flex:1; padding: 20px;border: 1px solid var(--very-light-purple);">
					<div v-if="!step3.unit">
						<div class="padding-box" style="height:200px; background:#f2f2f2;"></div>
						<a-skeleton :loading="true" />
					</div>
					<div v-else-if="currentUnit.id">
						<img :src="currentUnit.image" style="max-width:100%" />
						<div v-if="instance.productType == 'lowrise'">
							<div class="dF jSB" style="align-items:baseline">
								<span style="font-size:18px;">{{ currentUnit.unitGroup.name }} - {{ currentUnit.name
								}}</span>
							</div>
							<div class="dF jSB">
								<span>{{ currentPackage.beds }} Beds</span> |
								<span>{{ currentPackage.baths }} Baths</span> |
								<span>{{ currentPackage.garages }} Car Garage</span> |
								<span>{{ currentPackage.sqft }} {{ currentPackage.sqft2 && currentPackage.sqft2 > 0 ? `+
									${currentPackage.sqft2}` : '' }} Sq. Ft.</span>
							</div>
						</div>
						<div v-if="instance.productType == 'highrise'">
							<div class="dF jSB" style="align-items:baseline">
								<span style="font-size:18px;">{{ 'Unit #' + currentUnit.unitNumber }} -
									{{ currentUnit.name }}</span>
							</div>
							<div class="dF jSB">
								<span>{{ currentPackage.beds }} Beds</span> |
								<span>{{ currentPackage.baths }} Baths</span> |
								<span>{{ currentPackage.parking }} Parking</span> |
								<span>{{ currentPackage.sqft }} Sq. Ft.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { findById } from 'bh-mod'
import { drawLot } from 'bh-mod/helper'
import moment from 'moment';

export default {
	props: ['reservation'],
	data: () => ({
		notApplicable: false,
		foundLot: false,
		foundModel: false,
		productNotFound: false,
		condoFloors: [],
		currentSiteplan: {},
		lots: [],
		units: [],
		selectedBricks: [],
		packages: [],
		highRisePremiums: {},
		loadingUnits: false,
		step3: {
			lot: '',
			floor: '',
			unit: '',
			package: '',
			purchasePrice: 0,
			siteplanImage: '',
			lotImage: '',
		}
	}),
	computed: {
		floorUnits() {
			if (!this.step3.floor || !this.step3.floor.trim()) return []
			let floor = this.condoFloors.find(x => x.id === this.step3.floor)
			if (!floor) return false
			return floor.units
		},
		instance() {
			let instance = this.$store.state.instance
			return instance
		},
		currentLot() {
			if (!this.step3.unit) return {}
			let found = findById(this.lots, this.step3.lot)

			if (!found) found = {}

			return found
		},
		msrp() {
			if (this.instance.productType === 'lowrise') {
				if (!this.step3.lot || !this.step3.unit || !this.currentLot || !this.currentLot.id || !this.currentPackage || !this.currentPackage.id) return 0
				return (this.currentPackage && this.currentPackage.price) + (this.lotPremiums)
			} else {
				if (!this.currentPackage || !this.currentPackage.id) return 0
				return (this.currentPackage && this.currentPackage.price) + this.unitPremiums
			}
		},
		lotPremiums() {
			let lot = this.currentLot
			let price = 0
			if (lot.other) {
				let premiums = Object.values(lot.other.premiums)
				if (Array.isArray(premiums)) {
					premiums.forEach(prm => {
						if (!prm.active) return
						if (prm.price && prm.price > 0) price += prm.price
					})
				}
			}
			return price
		},
		unitPremiums() {
			let unitPremiums = this.currentPackage.other && this.currentPackage.other.premiums || {}
			let price = 0

			Object.values(unitPremiums).filter(x => x).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})
			return price
		},
		currentPackage() {
			if (!this.currentUnit || !this.currentUnit.packages || !this.step3.package) return {}
			return this.currentUnit.packages.find(pkg => pkg.id === this.step3.package) || (this.currentUnit.packages.length && this.currentUnit.packages[0]) || {}
		},
		currentUnit() {
			let units = this.units
			if (this.instance.productType === 'highrise') units = this.floorUnits
			if (!this.step3.unit) return {}
			let found = units && units.find(unit => unit.id === this.step3.unit)
			if (!found) found = {}

			return found
		},
	},
	watch: {
		msrp(val) {
			this.step3.purchasePrice = val
			this.step3.totalRent = val
		},
		'step3.floor'(val) {
			this.step3.unit = ''
		},
		'step3.unit'(val) {
			let units = this.units
			if (this.instance.productType === 'highrise') units = this.floorUnits
			if (val && units) {
				let unit = units.find(x => x.id === val && x.packages && x.packages.length)
				if (!unit) {
					return;
				}
				this.packages = [...unit.packages]
				this.step3.package = (this.reservation.product && this.reservation.product.unit && this.reservation.product.unit.id == this.step3.unit && this.reservation.product.unit.package && this.reservation.product.unit.package.id) || unit.packages[0].id
				this.step3.purchasePrice = unit.packages[0].price
				this.step3.totalRent = unit.packages[0].price
			}
		},
		'step3.lot'(val) {
			if (!val) return

			this.units = []
			this.packages = []
			this.step3.package = ''
			this.step3.unit = ''

			this.loadingUnits = true
			this.$api.get(`/architectural-controls/:instance/availableunits/${val}`).then(async ({ data }) => {
				let units = data.units.filter(unit => {
					return unit.unitGroup && unit.unitGroup.id && unit.packages && unit.packages.length
				})

				if (this.reservation.product && this.reservation.product.unit && this.reservation.product.lot.id == this.step3.lot) {
					this.step3.unit = this.reservation.product.unit.id;
					this.step3.package = this.reservation.product.unit.package && this.reservation.product.unit.package.id || ''
				} else {
					this.step3.unit = ''
				}

				this.loadingUnits = false

				if (units.length === 0) return this.step3.unit === false

				this.currentSiteplan = data.siteplan

				this.units = units
				let { product, floor } = this.reservation.query

				if (this.reservation.query.hasOwnProperty('product') && Object.values(this.$route.query).length) {
					let foundUnit = this.units.findIndex(x => x.id == product)
					if (foundUnit != -1) {
						this.foundModel = true
						this.step3.unit = product
					} else {
						this.foundModel = false
						this.step3.unit = ''
						this.$message.error('The elevation is no longer available for this lot. Please select another elevation.', 5)
					}
				}

				if (data.attachedUnit) this.step3.unit = data.attachedUnit.id ? data.attachedUnit.id : data.attachedUnit
				if (data.attachedPackage) this.step3.package = data.attachedPackage.id ? data.attachedPackage.id : data.attachedPackage
				this.drawLot = drawLot
				drawLot.initCanvas()
				await drawLot.initShape(data.siteplan, data.shapeId)
			}).catch((err) => {
				this.loadingUnits = false
				this.step3.unit = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching unit list. Please select another unit'))
				}
			})
		},

	},
	methods: {
		formatPrice: (p) => {
			if (!p) {
				return p;
			}
			if (typeof p === 'string') p = parseFloat(parseFloat(p).toFixed(2))
			if (!p) return `0`
			return p.toLocaleString('en-us')
		},
		req: msg => ({ required: true, message: msg }),
		validate() {
			let self = this

			this.$refs.step3.validate(async valid => {
				if (valid) {
					this.step
					this.$store.commit('LOAD', true)
					let sendObj = {}

					if (!this.currentPackage.other) this.currentPackage.other = { premiums: {} }
					if (!this.currentPackage.other.premiums) this.currentPackage.other.premiums = {}

					try {
						const payload = this.instance.productType === 'lowrise' ? { lot: this.step3.lot } : { unit: this.step3.unit }
						let { data } = await this.$api.post('/product-locks', payload)
						this.$store.commit('SET_PRODUCT_LOCK', { time: data.lockedUntil, product: this.instance.productType === 'lowrise' ? data.lot : data.unit })
					} catch (err) {
						this.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `Error occurred while accruing lock on selected ${this.instance.productType === 'lowrise' ? 'lot' : 'unit'}. Please try again!`))
						}
						return;
					}

					if (this.instance.productType === 'lowrise') {
						sendObj = {
							product: {
								unit: this.currentUnit,
								lot: {
									...this.currentLot,
									siteplan: this.currentSiteplan
								},
							},
							offer: {
								basePrice: this.currentPackage && this.currentPackage.price,
								lotPremiums: this.lotPremiums,
								purchasePrice: this.step3.purchasePrice
							},
						}

						this.reservation.query.product = this.step3.unit
						this.reservation.query.floor = this.step3.lot

						if (!sendObj.product.lot.other) sendObj.product.lot.other = { premiums: {} }
						if (!sendObj.product.lot.other.premiums) sendObj.product.lot.other.premiums = {}

						Object.entries(sendObj.product.lot.other.premiums).forEach(([key, value]) => {
							if (!value.active) delete sendObj.product.lot.other.premiums[key]
						})

						sendObj.product.unit.package = this.currentPackage && { ...this.currentPackage }
						if (sendObj.product.unit.package && sendObj.product.unit.unitGroup) {
							sendObj.product.unit.unitGroup.name = sendObj.product.unit.unitGroup.name + ' - ' + sendObj.product.unit.package.name
						}
					} else if (this.instance.productType === 'highrise') {
						let floor = this.condoFloors.find(x => x.id === this.step3.floor)

						sendObj = {
							product: {
								unit: this.currentUnit,
								floor,
							},
							offer: {
								basePrice: this.currentPackage && this.currentPackage.price,
								purchasePrice: this.step3.purchasePrice,
							},
						}

						if (!this.currentPackage.other) this.currentPackage.other = { premiums: {} }
						if (!this.currentPackage.other.premiums) this.currentPackage.other.premiums = {}
						sendObj.product.unit.package = { ...this.currentPackage }

						this.reservation.query.product = this.step3.unit
						this.reservation.query.floor = this.step3.floor
					}

					// check for unit premiums and set the name and filter our inactive premiums
					if (sendObj.product.unit.package && sendObj.product.unit.package.other && sendObj.product.unit.package.other.premiums && Object.keys(sendObj.product.unit.package.other.premiums).length) {
						let newObj = {}
						Object.entries(sendObj.product.unit.package.other.premiums).forEach(([key, value]) => {
							if (value && value.active) {
								let { value: price } = value
								if (!price) price = 0
								newObj[key] = {
									id: key,
									active: true,
									value: +price,
									name: this.highRisePremiums[key]
								}
							}
						})
						sendObj.product.unit.package.other.premiums = newObj
					}
					this.$store.commit('LOAD', false)
					self.$emit('done', sendObj)
				}
			})
		},

		checkUnitAvailability(unitId) {
			return this.floorUnits.find(u => u.id === unitId && u.salesStatus !== 'sold')
		},

		checkLotAvailability(lotId) {
			return this.lots.find(u => u.id === lotId)
		}
	},
	mounted() {
		if (this.instance.productType === 'lowrise') {
			this.$api.get(`/architectural-controls/:instance/availablelots`).then(({ data }) => {
				let lots = data
				if (this.$route.params.id && this.reservation.product && this.reservation.product.lot) {
					const found = lots.find(l => l.id == this.reservation.product.lot.id)
					if (!found) {
						lots.push(this.reservation.product.lot)
					}
				}
				this.lots = lots.sort((a, b) => {
					//convert a.name and b.name to a number and then sort by lowest number to highest number
					return +a.name - +b.name
				})
				let { product, floor } = this.reservation.query
				if (Object.values(this.$route.query).length && product && product.length) {
					let foundProduct = this.lots.find(x => x.id === floor)
					if (foundProduct) {
						this.step3.lot = floor
						this.foundLot = true
					} else {
						this.productNotFound = true
						this.foundLot = false
						this.$message.error('Could not find the lot linked to Opportunity. Please select another lot', 5)
					}
				}

				if (this.reservation && this.reservation.product && this.reservation.product.lot) {
					if (this.checkLotAvailability(this.reservation.product.lot.id)) {
						this.step3.lot = this.reservation.product.lot.id;
					}
				}
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		} else {
			this.$api.get('/settings/:instance/condounits').then(({ data }) => {
				if (data) {
					this.$store.commit('SET_CONDO_SETTINGS', data)
				}
				let premiums = [
					{
						name: 'Floor Premium',
						value: '',
						id: 'floorPremium',
					},
					{
						name: 'View Premium',
						value: '',
						id: 'viewPremium',
					},
					{
						name: 'Balcony Premium',
						value: '',
						id: 'balconyPremium',
					},
					{
						name: 'Terrace Premium',
						value: '',
						id: 'terracePremium',
					},
				]
				if (data && data.options && data.options.premiums && data.options.premiums.length) {
					premiums = [...premiums, ...data.options.premiums]
				}
				premiums.forEach(prm => {
					this.highRisePremiums[prm.id] = prm.name
				})
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
			this.$api.get(`/unit-groups/:instance`).then(({ data }) => {
				data = data.filter(x => x.buildingType === 'condounits').map(floor => {
					floor.sold = true
					floor.disabled = true
					if (floor.units.length) floor.disabled = false
					floor.units = floor.units.filter(x => x.salesStatus && !x.salesStatus.includes('sold'))
					if (floor.units.length) floor.sold = false
					return floor
				})
				this.condoFloors = data
				let { product, floor } = this.reservation.query
				if (floor && floor.length && product && product.length) {
					this.$store.commit('LOAD', true)
					try {
						this.step3.floor = floor
						this.$nextTick(() => {
							setTimeout(() => {
								if (this.checkUnitAvailability(product)) {
									this.step3.unit = product
								}
								this.$store.commit('LOAD', false)
							}, 20);
						})
					} catch (err) {
						this.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, 'Could not find the floor/unit linked to Opportunity. Please select another unit'))
						}
					}
				}


				if (this.reservation.product && this.reservation.product.floor && this.reservation.product.unit) {
					this.step3.floor = this.reservation.product.floor.id;
					// Adding timeout to calculate depended computed value properly
					setTimeout(() => {
						if (this.checkUnitAvailability(this.reservation.product.unit.id)) {
							this.step3.unit = this.reservation.product.unit.id;
							this.step3.package = this.reservation.product.unit.package && this.reservation.product.unit.package.id || ''
						}
					}, 100)
				}
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		}

		if (this.reservation.offer) {
			this.step3.purchasePrice = this.reservation.offer.purchasePrice || 0
		}

		this.$emit('setFn', this.validate)
	}
}
</script>

<style>
</style>
